var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "page-user-edit" } },
    [
      _c(
        "vs-alert",
        {
          attrs: {
            color: "danger",
            title: "User Not Found",
            active: _vm.user_not_found,
          },
          on: {
            "update:active": function ($event) {
              _vm.user_not_found = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v(
              "User record with id: " +
                _vm._s(_vm.$route.params.userId) +
                " not found. "
            ),
          ]),
          _c(
            "span",
            [
              _c("span", [_vm._v("Check ")]),
              _c(
                "router-link",
                {
                  staticClass: "text-inherit underline",
                  attrs: { to: { name: "page-user-list" } },
                },
                [_vm._v("All Users")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.user_data
        ? _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "tabs-container px-6 pt-6",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "vs-tabs",
                  {
                    staticClass: "tab-action-btn-fill-conatiner",
                    model: {
                      value: _vm.activeTab,
                      callback: function ($$v) {
                        _vm.activeTab = $$v
                      },
                      expression: "activeTab",
                    },
                  },
                  [
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Account",
                          "icon-pack": "feather",
                          icon: "icon-user",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("user-edit-tab-account", {
                              staticClass: "mt-4",
                              attrs: { data: _vm.user_data },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Information",
                          "icon-pack": "feather",
                          icon: "icon-info",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("user-edit-tab-information", {
                              staticClass: "mt-4",
                              attrs: { data: _vm.user_data },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "vs-tab",
                      {
                        attrs: {
                          label: "Social",
                          "icon-pack": "feather",
                          icon: "icon-share-2",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tab-text" },
                          [
                            _c("user-edit-tab-social", {
                              staticClass: "mt-4",
                              attrs: { data: _vm.user_data },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }