var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "user-edit-tab-info" } }, [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2" },
        [
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "url:require_protocol",
                expression: "'url:require_protocol'",
              },
            ],
            staticClass: "w-full",
            attrs: {
              "icon-pack": "feather",
              icon: "icon-twitter",
              label: "Twitter",
              "icon-no-border": "",
              name: "twitter",
            },
            model: {
              value: _vm.data_local.social_links.twitter,
              callback: function ($$v) {
                _vm.$set(_vm.data_local.social_links, "twitter", $$v)
              },
              expression: "data_local.social_links.twitter",
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("twitter"),
                  expression: "errors.has('twitter')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("twitter")))]
          ),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "url:require_protocol",
                expression: "'url:require_protocol'",
              },
            ],
            staticClass: "w-full mt-4",
            attrs: {
              "icon-pack": "feather",
              icon: "icon-facebook",
              label: "Facebook",
              "icon-no-border": "",
              name: "facebook",
            },
            model: {
              value: _vm.data_local.social_links.facebook,
              callback: function ($$v) {
                _vm.$set(_vm.data_local.social_links, "facebook", $$v)
              },
              expression: "data_local.social_links.facebook",
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("facebook"),
                  expression: "errors.has('facebook')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("facebook")))]
          ),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "url:require_protocol",
                expression: "'url:require_protocol'",
              },
            ],
            staticClass: "w-full mt-4",
            attrs: {
              "icon-pack": "feather",
              icon: "icon-instagram",
              label: "Instagram",
              "icon-no-border": "",
              name: "instagram",
            },
            model: {
              value: _vm.data_local.social_links.instagram,
              callback: function ($$v) {
                _vm.$set(_vm.data_local.social_links, "instagram", $$v)
              },
              expression: "data_local.social_links.instagram",
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("instagram"),
                  expression: "errors.has('instagram')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("instagram")))]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/2" },
        [
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "url:require_protocol",
                expression: "'url:require_protocol'",
              },
            ],
            staticClass: "w-full mt-4 md:mt-0",
            attrs: {
              "icon-pack": "feather",
              icon: "icon-github",
              label: "GitHub",
              "icon-no-border": "",
              name: "github",
            },
            model: {
              value: _vm.data_local.social_links.github,
              callback: function ($$v) {
                _vm.$set(_vm.data_local.social_links, "github", $$v)
              },
              expression: "data_local.social_links.github",
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("github"),
                  expression: "errors.has('github')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("github")))]
          ),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "url:require_protocol",
                expression: "'url:require_protocol'",
              },
            ],
            staticClass: "w-full mt-4",
            attrs: {
              "icon-pack": "feather",
              icon: "icon-codepen",
              label: "CodePen",
              "icon-no-border": "",
              name: "codepen",
            },
            model: {
              value: _vm.data_local.social_links.codepen,
              callback: function ($$v) {
                _vm.$set(_vm.data_local.social_links, "codepen", $$v)
              },
              expression: "data_local.social_links.codepen",
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("codepen"),
                  expression: "errors.has('codepen')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("codepen")))]
          ),
          _c("vs-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "url:require_protocol",
                expression: "'url:require_protocol'",
              },
            ],
            staticClass: "w-full mt-4",
            attrs: {
              "icon-pack": "feather",
              icon: "icon-slack",
              label: "Slack",
              "icon-no-border": "",
              name: "slack",
            },
            model: {
              value: _vm.data_local.social_links.slack,
              callback: function ($$v) {
                _vm.$set(_vm.data_local.social_links, "slack", $$v)
              },
              expression: "data_local.social_links.slack",
            },
          }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("slack"),
                  expression: "errors.has('slack')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("slack")))]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c("div", { staticClass: "vx-col w-full" }, [
        _c(
          "div",
          { staticClass: "mt-8 flex flex-wrap items-center justify-end" },
          [
            _c(
              "vs-button",
              {
                staticClass: "ml-auto mt-2",
                attrs: { disabled: !_vm.validateForm },
                on: { click: _vm.save_changes },
              },
              [_vm._v("Save Changes")]
            ),
            _c(
              "vs-button",
              {
                staticClass: "ml-4 mt-2",
                attrs: { type: "border", color: "warning" },
                on: { click: _vm.reset_data },
              },
              [_vm._v("Reset")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }