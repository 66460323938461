var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "user-edit-tab-info" } },
    [
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c("div", { staticClass: "flex items-start flex-col sm:flex-row" }, [
            _c("img", {
              staticClass: "mr-8 rounded h-24 w-24",
              attrs: { src: _vm.data.avatar },
            }),
            _c(
              "div",
              [
                _c(
                  "p",
                  { staticClass: "text-lg font-medium mb-2 mt-4 sm:mt-0" },
                  [_vm._v(_vm._s(_vm.data.name))]
                ),
                _c("input", {
                  ref: "update_avatar_input",
                  staticClass: "hidden",
                  attrs: { type: "file", accept: "image/*" },
                  on: { change: _vm.update_avatar },
                }),
                _c("vs-button", { staticClass: "mr-4 mb-4" }, [
                  _vm._v("Change Avatar"),
                ]),
                _c(
                  "vs-button",
                  { attrs: { type: "border", color: "danger" } },
                  [_vm._v("Remove Avatar")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col md:w-1/2 w-full" },
          [
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|alpha_num",
                  expression: "'required|alpha_num'",
                },
              ],
              staticClass: "w-full mt-4",
              attrs: { label: "Username", name: "username" },
              model: {
                value: _vm.data_local.username,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "username", $$v)
                },
                expression: "data_local.username",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("username"),
                    expression: "errors.has('username')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("username")))]
            ),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|alpha_spaces",
                  expression: "'required|alpha_spaces'",
                },
              ],
              staticClass: "w-full mt-4",
              attrs: { label: "Name", name: "name" },
              model: {
                value: _vm.data_local.name,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "name", $$v)
                },
                expression: "data_local.name",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("name"),
                    expression: "errors.has('name')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("name")))]
            ),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'",
                },
              ],
              staticClass: "w-full mt-4",
              attrs: { label: "Email", type: "email", name: "email" },
              model: {
                value: _vm.data_local.email,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "email", $$v)
                },
                expression: "data_local.email",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("email"),
                    expression: "errors.has('email')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("email")))]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/2 w-full" },
          [
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Status"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    clearable: false,
                    options: _vm.statusOptions,
                    name: "status",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  model: {
                    value: _vm.status_local,
                    callback: function ($$v) {
                      _vm.status_local = $$v
                    },
                    expression: "status_local",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("status"),
                        expression: "errors.has('status')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("status")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Role"),
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  attrs: {
                    clearable: false,
                    options: _vm.roleOptions,
                    name: "role",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  model: {
                    value: _vm.role_local,
                    callback: function ($$v) {
                      _vm.role_local = $$v
                    },
                    expression: "role_local",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("role"),
                        expression: "errors.has('role')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("role")))]
                ),
              ],
              1
            ),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "alpha_spaces",
                  expression: "'alpha_spaces'",
                },
              ],
              staticClass: "w-full mt-4",
              attrs: { label: "Company", name: "company" },
              model: {
                value: _vm.data_local.company,
                callback: function ($$v) {
                  _vm.$set(_vm.data_local, "company", $$v)
                },
                expression: "data_local.company",
              },
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("company"),
                    expression: "errors.has('company')",
                  },
                ],
                staticClass: "text-danger text-sm",
              },
              [_vm._v(_vm._s(_vm.errors.first("company")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "vx-card",
        {
          staticClass: "mt-base",
          attrs: { "no-shadow": "", "card-border": "" },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "div",
                  { staticClass: "flex items-end px-3" },
                  [
                    _c("feather-icon", {
                      staticClass: "mr-2",
                      attrs: { svgClasses: "w-6 h-6", icon: "LockIcon" },
                    }),
                    _c(
                      "span",
                      { staticClass: "font-medium text-lg leading-none" },
                      [_vm._v("Permissions")]
                    ),
                  ],
                  1
                ),
                _c("vs-divider"),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "block overflow-x-auto" }, [
            _c(
              "table",
              { staticClass: "w-full" },
              [
                _c(
                  "tr",
                  _vm._l(
                    ["Module", "Read", "Write", "Create", "Delete"],
                    function (heading) {
                      return _c(
                        "th",
                        {
                          key: heading,
                          staticClass:
                            "font-semibold text-base text-left px-3 py-2",
                        },
                        [_vm._v(_vm._s(heading))]
                      )
                    }
                  ),
                  0
                ),
                _vm._l(_vm.data_local.permissions, function (val, name) {
                  return _c(
                    "tr",
                    { key: name },
                    [
                      _c("td", { staticClass: "px-3 py-2" }, [
                        _vm._v(_vm._s(name)),
                      ]),
                      _vm._l(val, function (permission, name) {
                        return _c(
                          "td",
                          { key: name + permission, staticClass: "px-3 py-2" },
                          [
                            _c("vs-checkbox", {
                              model: {
                                value: val[name],
                                callback: function ($$v) {
                                  _vm.$set(val, name, $$v)
                                },
                                expression: "val[name]",
                              },
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                }),
              ],
              2
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "vx-row" }, [
        _c("div", { staticClass: "vx-col w-full" }, [
          _c(
            "div",
            { staticClass: "mt-8 flex flex-wrap items-center justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "ml-auto mt-2",
                  attrs: { disabled: !_vm.validateForm },
                  on: { click: _vm.save_changes },
                },
                [_vm._v("Save Changes")]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "ml-4 mt-2",
                  attrs: { type: "border", color: "warning" },
                  on: { click: _vm.reset_data },
                },
                [_vm._v("Reset")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }